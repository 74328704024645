import React from "react"
import Topic from "../../components/Topic"
import { Link } from "gatsby"

const EmergencyAndCrisis = () => (
  <Topic id="emergency">
    <p>
      In Canada, <strong>you call 9-1-1</strong> on the phone to get help when
      there is a life-threatening emergency. Call 9-1-1 when someone&apos;s
      health, safety or property is in <strong>immediate danger</strong> or
      there is a crime in progress. It connects you to emergency services for
      Paramedics, Police and Fire. It is free and the operator can get someone
      who speaks your language, if needed. Simply tell the operator what
      language you speak (for example Arabic, Farsi, Ukrainian, etc.) Stay calm,
      speak clearly and wait for help.
    </p>
    <p>
      When you are sick, it is NOT an emergency. Please refer to{" "}
      <Link to="/settling/health_and_medical/">Health & Medical</Link> for more
      information.
    </p>
    <p>
      When you have a health, food or shelter crisis that is not a
      life-threatening emergency you can use{" "}
      <a href="https://211.ca/#/" target="_blank" rel="noreferrer">
        211
      </a>{" "}
      to find help. 211 is a free online tool to help you find government and
      community-based non-clinical health and social services.
    </p>
    <p>
      In this section, you can learn about where to get help when experiencing a
      crisis. It includes places to get help for suicide prevention and other
      mental health crises, violence and abuse. There are many community and
      church-based food programs for those who are in need of food. There are
      also emergency shelters if you find yourself with no place to live. If you
      are a refugee claimant, you will want to contact one of the shelters
      specifically designed for claimants. If you are a woman experiencing
      abuse, you should look for one of the shelters that serves women and
      children experiencing family violence and abuse. Homeless shelters are
      also available, but may not be the best option for newly arrived refugees.
    </p>
  </Topic>
)

export default EmergencyAndCrisis
